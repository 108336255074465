@font-face {
  font-family: "Rubik";
  src: url("./assets/font/Rubik/Rubik-Regular.ttf") format("truetype");
}

@font-face {
  font-family: "Rubik-Bold";
  src: url("./assets/font/Rubik/Rubik-Bold.ttf") format("truetype");
}

.custom-modal-font {
  font-family: "Rubik" !important;
}
body {
  margin: 0;
  font-family: "Rubik";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: transparent;
}

/* Navbar styling */
nav {
  background-color: #fff !important;
  transition: all 0.3s linear;
  border-radius: 12px;
  box-shadow: rgba(67, 71, 85, 0.27) 0px 0px 0.25em, rgba(90, 125, 188, 0.05) 0px 0.25em 1em;
  margin: 10px;
  width: 97vw;
}
.user-profile-mobile {
  margin-top: 10px;
  font-family: "Rubik";
}
@media screen and (max-width: 991px) {
  nav {
    margin: 5px 0px;
    width: 95vw !important;
  }
}

.navbar {
  position: relative;
}
.navbar-menu-container {
  width: 100%;
  padding: 0px 20px;
}

.navbar-nav {
  width: 100%;
}

.basic-menu {
  margin-top: 10px;
}

.basic-menu-item {
  border-radius: 8px !important;
}

.basic-menu-item:hover .profile-icon,
.basic-menu-item:hover .logout-icon {
  color: white !important;
}
.basic-menu-item:nth-child(1) {
  padding-left: 12px !important;
}
.profile-btn {
  border: 1px solid rgb(99, 167, 255) !important;
  margin: 5px 10px !important;
  padding: 0;
}
.profile-btn:hover {
  background-color: #5c7ce5 !important;
  color: white;
}

.logout-btn {
  border: 1px solid rgb(88, 88, 88) !important;
  margin: 5px 10px !important;
  transition: background-color 0.3s linear;
}
.logout-btn:hover {
  background-color: #5c7ce5 !important;
  color: white;
}

.loading-anim-profile {
  width: 100px !important;
  height: 100px !important;
}

.user-profile-badge {
  display: flex;
  align-items: flex-end;
  cursor: pointer;
  box-shadow: rgba(98, 157, 253, 0.829) 0px 1px 4px;
  border-radius: 10px;
  padding: 10px 20px;
  color: rgba(3, 101, 214, 0.612);
  gap: 10px;
}
.user-profile-badge:nth-child(2) {
  display: flex;
  align-items: flex-end;
  cursor: pointer;
  box-shadow: rgba(98, 157, 253, 0.829) 0px 1px 4px;
  border-radius: 10px;
  padding: 6px 10px;
  color: rgba(3, 101, 214, 0.612);
  gap: 10px;
}

/* ---------------- Profile card styling ----------------*/
.profile-container {
  font-family: "Rubik";
}
.profile-img {
  height: 100px;
}

.profile-icon {
  width: 60px;
  height: 60px;
  background-color: #5c7ce5;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.profile-pencil-icon {
  cursor: pointer;
  background-color: #5c7ce5;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  width: 37px;
  height: 35px;
  border-radius: 12px;
  margin: 0px 10px;
  transition: box-shadow 0.3s linear;
}
.profile-pencil-icon:hover {
  /* elevation shadow effect */
  box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.5);
}

.profile-icon p {
  padding: 0;
  margin: 0;
  color: white;
  font-size: 2em;
}

.left-profile-bottom > p {
  background-color: #5c7ce5;
  width: fit-content;
  color: white;
  border-radius: 5px;
  padding: 2px 6px;
  margin: 0;
}

.right-profile-bottom > p {
  background-color: #5c7ce5;
  width: fit-content;
  color: white;
  border-radius: 5px;
  padding: 2px 6px;
  margin: 0;
}
.audio-statistics p {
  font-size: 14px;
  margin: 5px;
}
.text-statistics p {
  font-size: 14px;
  margin: 5px;
}

/* --------------Profile card styling end----------------- */

.nav-username {
  font-size: large;
}
.navbar-nav .nav-link {
  font-size: 25px !important;
  color: #858585;
  font-weight: bold !important;
  transform: all 0.3s linear !important;
  position: relative;
  display: inline-block;
  border-radius: 40px;
  font-family: "Rubik";
  white-space: nowrap;
  text-align: center;
}
.navbar-nav .nav-link-mob {
  display: flex;
  color: #06002e;
  align-items: center;
  gap: 5px;
  justify-content: center;
  font-weight: bold !important;
  transform: all 0.3s linear !important;
  font-family: "Rubik";
  margin: 0 0 10px 0px;
}

.nav-link-mob img {
  width: 38px;
  height: 34px;
}
.nav-link-mob p {
  margin: 5px 0 0 0;
  padding: 0;
}

.nav-link:hover {
  text-shadow: 0 0 #f5f5f5 !important;
}

.nav-items {
  width: 100%;
  padding: 0;
  margin: 0;
}

.navbar-nav .nav-item:hover .nav-link::after {
  transform: translateX(-50%) scale(1);
}
.user-profile-img {
  width: 30px;
  height: 30px;
  object-fit: cover;
  box-shadow: rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;
  border-radius: 50px;
}
.burger-menu-container {
  width: 98%;
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
}

.back-btn {
  border: none;
  background-color: #5c7ce5;
  color: white;
  border-radius: 14px;
  width: auto;
  height: 45px;
  display: flex;
  align-items: center;
  padding: 5px 20px;
  gap: 5px;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
  transition: box-shadow 0.5s;
}
@media screen and (max-width: 435px) {
  .back-btn {
    left: 15px;
  }
}
@media screen and (max-width: 361px) {
  .back-btn {
    left: 8px;
  }
  .call-analysis-header {
    margin-left: 20px;
  }
}

@media (min-width: 768px) and (max-width: 905px) {
  .audio-filter-search {
    display: flex;
    flex-direction: column !important;
  }
}
.back-btn p {
  color: #43addb;
}
.back-btn:hover {
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5); /* adjust values as needed */
}
.toggle-btn {
  background-color: transparent;
  border: none;
  font-size: 1.5rem;
  color: gray;
}

.mobile-nav {
  background: #fff;
  position: fixed;
  width: 200px;
  right: -200px;
  top: 40px;
  bottom: 0;
  padding: 1rem !important;
  transition: all 0.3s linear;
  z-index: 10;
}

.open-mobile-nav {
  right: 0;
}

.close-icon {
  font-size: 1.5rem;
  color: black;
}

.close-btn {
  background: transparent;
  border: none;
}

/* Home Styling */
.home-container {
  padding: 10px 10px 0 10px;
}

.labels {
  color: #000;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 183.2%;
}

.card {
  padding: 30px;
  top: auto;
  max-width: 100%;
  max-height: 300px;
  height: 100%;
  border: dashed;
  border-color: rgba(13, 110, 253, 0.25);
  border-radius: 15px;
  background-color: #fafafa !important;
  box-shadow: 3px 2px 4px rgba(0, 0, 0, 0.1);
}

@media (max-width: 768px) {
  .card {
    max-height: 500px;
  }
}

@media (max-width: 400px) {
  .card {
    max-height: 600px;
  }
  .audio-select-container {
    height: 100px;
  }
}

@media (max-width: 315px) {
  .card {
    max-height: 700px;
  }
}

@media (max-width: 275px) {
  .card {
    max-height: 800px;
  }
}

@media (max-width: 245px) {
  .card {
    max-height: 900px;
  }
}

@media (max-width: 225px) {
  .card {
    max-height: 1000px;
  }
}

.card-text {
  max-width: 700px;
  width: 100%;
}

@media (max-width: 768px) {
  .card-text {
    width: 100%;
  }
}

/* Audio graph styling */
.apexcharts-toolbar {
  display: none !important;
}

.home-container {
  background-color: #f7f7fd;
}

.audiochart {
  border-radius: 12px;
  background-color: white !important;
  height: 360px;
  font-family: "Onest ", sans-serif;
  border: 1px solid rgb(227, 227, 227);
}

@media screen and (max-width: 992px) {
  .audiochart {
    height: 420px !important;
  }
}

.css-1fw5qro-MuiPaper-root {
  box-shadow: 3px 2px 4px rgba(0, 0, 0, 0.1) !important;
}

/* Audio Table styling */
.css-pdct74-MuiTablePagination-selectLabel {
  margin-top: auto;
  font-family: "Rubik";
  font-weight: 400;
  font-size: 0.875rem;
  line-height: 1.43;
  letter-spacing: 0.01071em;
  -webkit-flex-shrink: 0;
  -ms-flex-negative: 0;
  flex-shrink: 0;
}

.css-levciy-MuiTablePagination-displayedRows {
  margin-top: auto;
  font-family: "Rubik";
  font-weight: 400;
  font-size: 0.875rem;
  line-height: 1.43;
  letter-spacing: 0.01071em;
  -webkit-flex-shrink: 0;
  -ms-flex-negative: 0;
  flex-shrink: 0;
}

.eye-icon {
  cursor: pointer;
}

/* Selected Audio file styling */
.card-selected-audio {
  padding: 30px;
  top: auto;
  max-width: 100%;
  border-radius: 15px;
  background-color: #fafafa !important;
  border: 1px solid #f7eedc;
}

.uploaded-files-progress {
  width: fit-content;
  display: flex;
  align-items: center;
  border-radius: 10px;
  padding: 5px;
  color: #5c7ce5;
  font-weight: bold;
}
.spinner-border {
  color: #5c7ce5;
}
.processing-files-txt {
  font-size: 17px;
  margin: 0 10px;
}
.processing-files-txt2 {
  width: 200px;
  background-color: #5c7ce5;
  color: white !important;
  padding: 2px 6px;
  font-size: 14px;
  border-radius: 6px;
  white-space: normal;
  word-wrap: break-word;
}
.processing-files-txt2::marker {
  color: gray;
}
.audio-summary {
  margin: 100px 0 0 0;
}

.audio-chart {
  max-width: 500px;
}

.table {
  color: #606070;
}

.table-body {
  padding: 15px;
}

.table-td {
  padding: 5px 0 5px 50px;
}

.table-th {
  font-style: normal;
}

.audio-file-source {
  width: 100%;
}

.exportcsv-icon {
  cursor: pointer;
}
.delete-icon {
  cursor: pointer;
  color: red;
}

.download-btn {
  background-color: #43addb;
  border-radius: 10px;
  cursor: pointer;
  color: white;
  gap: 10px;
  padding: 5px 10px;
  transition: box-shadow 0.3s ease-in-out;
}

.download-btn:hover {
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 10px;
}

.download-btn p {
  font-size: large;
}

.btn-deposit,
.btn-withdraw {
  height: 40px;
  width: 200px;
  right: 0;
  border-radius: 8px;
  background-color: #43addb;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.2rem;
  font-weight: 500;
  text-shadow: 0 0 white;
  transition: all 0.3s linear;
  outline: 0;
  border: 0;
  margin-top: 16px;
  cursor: pointer;
}

.btn-cust-chart {
  margin-right: 12px;
}

.btn-deposit:focus,
.btn-withdraw:focus {
  box-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0.25);
}

.selected-audio-last-div {
  margin-top: -100px;
}

.togglebuttons {
  padding: 7px 15px;
  border-radius: 20px;
  background-color: #43addb;
  color: floralwhite;
  cursor: pointer;
}

/* Search field styling */
.search-container {
  display: flex;
  align-items: center;
  background-color: white;
  border: 1px solid lightgray;
  padding: 5px 10px;
  border-radius: 14px;
  transition: width 0.3s ease-in-out;
  width: 250px;
}

.search-container.expanded {
  width: 300px;
}

.search-form {
  border-radius: 18px;
  background-color: #f9f9fa;
  height: 55px;
}

.search-input {
  border: none;
  outline: none;
  flex-grow: 1;
  padding: 5px;
  background-color: white;
}

.search-button {
  background-color: transparent;
  border: none;
  cursor: pointer;
  /* margin-top: 8px; */
  padding: 5px;
}

.search-button i {
  color: #333;
}

td,
tr {
  padding: 1rem !important;
}

.audio-select-container {
  display: flex;
  text-align: center;
  justify-content: center;
  flex-direction: column;
  max-width: 100%;
  max-height: 300px;
  cursor: pointer;
}

.audio-select-container p {
  text-align: center;
  font-size: 16px;
}

.cloud-img {
  width: 100px;
  height: 100px;
  object-fit: contain;
}

.audio-dropdown {
  display: flex;
  justify-content: center;
  margin: 0 auto;
}

.dropdown-button-audio {
  padding: 7px 20px;
  background-color: #43addb;
  border: 1px solid #ccc;
  border-radius: 8px;
  font-weight: 500;
  color: white;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.card__side {
  transition: all 0.8s ease;
  /* margin-right: 10px; */
  position: absolute;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  overflow: hidden;
}

.card__side--back {
  display: flex;
  align-items: center;
  justify-content: center;
  transform: rotateY(180deg);
}

.flip-back-content {
  text-align: center;
  padding: 0;
}

.card:hover .card__side--back {
  transform: rotateY(0);
}

.card:hover .card__side--front {
  transform: rotateY(-180deg);
}

.upload-audio-btn {
  padding: 10px 20px;
  background-color: #5c7ce5;
  border: 1px solid #ccc;
  color: white;
  border-radius: 14px;
  font-weight: 500;
  cursor: pointer;
  width: fit-content;
  display: flex;
  align-items: center;
}
.btn-noBg {
  padding: 8px 20px;
  background-color: transparent;
  color: #5c7ce5;
  border: 1px solid #5c7ce5;
  border-radius: 14px;
  font-weight: 500;
  cursor: pointer;
  width: fit-content;
  display: flex;
  align-items: center;
}

.upload-text-btn {
  width: 100%;
  padding: 5px 15px;
  margin: 12px auto;
  background-color: #43addb;
  color: white;
  border: 1px solid #ccc;
  border-radius: 8px;
  font-weight: 500;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

/* Landing page */
.card-container {
  perspective: 1000px;
  width: 100%;
  height: fit-content;
  position: relative;
}

.custom-card {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  border-radius: 30px;
  cursor: pointer;
  width: 100%;
  height: 520px;
  transform-style: preserve-3d;
  transition: transform 0.7s;
}

.front-side,
.back-side {
  width: 100%;
  min-height: 80%;
  height: fit-content;
  backface-visibility: hidden;
  position: absolute;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 30px;
}

.front-side h2,
.back-side h2 {
  font-weight: bold;
  font-family: "Onest";
}
.front-side {
  background-color: #fff;
  transform: rotateY(0deg);
  z-index: 1;
}

.back-side {
  background-color: #fff;
  transform: rotateY(180deg);
  z-index: 0;
}
.check-icon {
  width: 24px;
  height: 24px;
  display: flex;
  padding: 5px !important;
  align-items: center;
  justify-content: center;
  background: linear-gradient(180deg, #5c7ce5 0%, #33b3db 100%);
  color: white;
  border-radius: 50%;
}

.flipped {
  transform: rotateY(180deg);
}

.btn-primary {
  background-color: #43addb;
  border-color: transparent;
}

.btn-primary:hover {
  background-color: #43addb;
  border-color: transparent;
}

.content {
  font-size: 1.1rem;
}

@media (min-width: 1200px) and (max-width: 1400px) {
  .card {
    width: 100%;
    height: 570px;
  }
}

@media (min-width: 992px) and (max-width: 1199px) {
  .custom-card {
    width: 100%;
    height: 500px;
  }

  .content {
    font-size: 1rem;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .custom-card {
    width: 100%;
    height: 470px;
  }

  .content {
    font-size: 0.95rem;
  }
}

@media (max-width: 766px) {
  .content {
    font-size: 0.95rem;
  }

  .card-container {
    margin-top: 2rem;
  }

  .custom-card {
    width: 100%;
    height: 450px;
  }
}

@media (min-width: 270px) and (max-width: 413px) {
  .custom-card {
    width: 100%;
    height: 480px;
  }
}

.content-size {
  padding: 0 15px 0 35px !important;
}

.selected-audio-list {
  display: flex;
  flex-direction: column;
  width: fit-content;
  align-self: center;
  height: fit-content;
  max-height: 200px !important;
  overflow-y: auto;
  padding: 10px;
}
.selected-audio-list ol li::marker {
  margin-left: 0 !important;
}

#word {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
}

.apexcharts-canvas .apexcharts-toolbar {
  display: none;
}

.grid-area {
  display: grid;
  grid-template-columns: 1fr 500px;
  column-gap: 1rem;
}

.grid-section {
  display: grid;
  grid-template-columns: 1fr 1fr;
  height: 500px;
}

.bg-gray {
  border-radius: 15px !important;
  background-color: #fafafa !important;
  border: 1px solid #f7eedc;
}

.apexcharts-xaxis-label,
.apexcharts-yaxis-label {
  font-family: "Rubik";
  font-size: 12px !important;
  font-weight: normal !important;
  color: #666 !important;
}

.my-chart text {
  font-size: 16px !important;
}

@media (min-width: 1313px) {
  .pie-chart-width {
    width: 26% !important;
  }
}

@media (min-width: 1200px) {
  .chart-width {
    width: 72.14% !important;
  }
}

@media (max-width: 1199px) {
  .grid-area {
    grid-template-columns: 1fr;
    row-gap: 1.5rem;
    column-gap: 2rem;
  }

  .my-chart text {
    font-size: 12px !important;
  }
}

.loading-progress {
  width: 40%;
  height: 40%;
}

.table-last-row-audio {
  padding: 0;
  margin-top: -60px !important;
}

.view-report a {
  cursor: pointer !important;
  position: relative;
  z-index: 2;
  color: white;
  background-color: #43addb;
  border-radius: 5px;
  padding: 8px 15px;
  font-size: 16px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.2);
  font-weight: 600;
}

@media (max-width: 500px) {
  .css-78c6dr-MuiToolbar-root-MuiTablePagination-toolbar {
    padding-left: 16px;
    padding-top: 40px;
  }
}

.css-pdct74-MuiTablePagination-selectLabel {
  margin-top: 14px !important;
}

@media (max-width: 768px) {
  .welcome-audio-buttons {
    padding: 0;
  }
}

.admin-container {
  width: 100%;
  position: relative;
  min-height: 100vh;
  display: flex;
  flex-wrap: wrap;
  padding: 15px;
  background: #f7f7fd;
  background-size: cover;
  background-repeat: no-repeat;
  font-family: "Onest", sans-serif;
}

.admin-container.landing{
  overflow-y: hidden;
}

.admin-container .landing-bg {
  position: absolute;
  right: 0;
  bottom: 0;
  background-size: cover;
}

.admin-login-fields {
  font-family: "Rubik";
  font-weight: normal;
  font-style: normal;
  font-size: 15px;
  line-height: 1.5;
  color: #666666;
  display: block;
  width: 100%;
  background: #e6e6e6;
  position: relative;
  z-index: 1;
  margin-bottom: 10px;
  border-radius: 30px !important;
}

a {
  text-decoration: none;
  margin: 0;
}

.css-1x51dt5-MuiInputBase-input-MuiInput-input {
  height: 2em !important;
  padding: 15px 0 5px !important;
}

.css-1x51dt5-MuiInputBase-input-MuiInput-input {
  padding: 12px !important;
}

.css-1yq5fb3-MuiButtonBase-root-MuiIconButton-root {
  margin: 0 0 0 -25px !important;
}

div:where(.swal2-container) button:where(.swal2-styled).swal2-confirm {
  border: none !important;
  background-color: #43addb !important;
}

.error-fields {
  font-size: 14px;
}

.css-9ddj71-MuiInputBase-root-MuiOutlinedInput-root,
.css-o9k5xi-MuiInputBase-root-MuiOutlinedInput-root,
.css-9msfkk-MuiFormControl-root-MuiTextField-root {
  border-radius: 14px !important;
}
div#country.MuiSelect-selected {
  border-radius: 14px !important;
}
/* .particles-bg-canvas-self{
min-height: 100vh;
} */

/* Validate OTP styling */
.otp-container {
  background-color: white;
  border-radius: 30px;
}
.otp-container img {
  width: 15%;
}

.verify-title {
  font-weight: 600;
  font-size: 24px;
  font-family: "Onest", sans-serif;
}

.customBtn {
  border-radius: 25px;
  padding: 10px 20px;
}

.verify-form input {
  display: inline-block;
  width: 50px;
  height: 50px;
  text-align: center;
}

.admin-reset-form {
  padding: 0 20%;
}

/* Report styling */
.content-audio {
  width: 500px;
}

.apexcharts-datalabel {
  color: black;
  text-shadow: 0 0 black;
  font-weight: 500;
  font-size: 12px;
}

.result-border {
  border: 1px solid black;
  border-radius: 10px;
  height: 200px;
}

.jumbotron {
  margin: 0px auto;
  padding: 240px 30px;
  background-image: linear-gradient(90deg, #5c7ce5 0%, #33b3db 100%);
  color: floralwhite;
}

.contact-bg {
  background-color: #f1f1f5;
  border-radius: 12px;
  width: 20%;
  margin: auto;
  display: block;
  text-align: center;
  align-items: center;
}

.contact-p {
  color: #5cb3cf;
  font-weight: bold;
}

.contentFooter {
  padding: 5% 20% 2% 20%;
  text-align: center;
  border: none;
  margin-top: 700px;
}

@media (max-width: 250px) {
  .contentFooter {
    margin-top: 950px;
  }
}

.chat-bubble1 {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 19px;
  height: 19px;
  border-radius: 50%;
  background-color: rgb(208, 208, 208);
  background: linear-gradient(180deg, rgba(92, 124, 229, 0.3) 0%, rgba(51, 179, 219, 0.3) 100%);
}
.chat-bubble2 {
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background: linear-gradient(180deg, #5c7ce5 0%, #33b3db 100%);
}

.performance-graph-section {
  padding: 0 2rem;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

/* TKT-VOX-008 fix container margin from right */
@media ((min-width: 767px) and (max-width: 992px)) {
  .card__side {
    margin-right: 10px;
  }
}

@media ((min-width: 601px) and (max-width: 1300px)) {
  .performance-graph-section {
    padding: 0 2rem;
  }
}

@media (max-width: 980px) {
  .summary-graph-side {
    align-self: center;
  }
}

@media (max-width: 991px) {
  .navbar {
    position: relative;
    width: 100%;
  }
}

@media (max-width: 600px) {
  .performance-graph-section {
    padding: 0 1rem;
  }

  .result-border {
    padding: 2rem 2rem;
    height: 150px;
  }

  .content-audio {
    width: 100%;
  }
}

@media (max-width: 519px) {
  .apexcharts-datalabel {
    font-size: 10px;
  }
}

.css-ph9425-MuiStepConnector-root .MuiStepConnector-line {
  height: 8px !important;
}

.css-1v4ccyo,
.css-1bn53lx {
  border-radius: 30px !important;
}

/* Reset Password Screen */
.reset-btn {
  width: 40%;
  padding: 10px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  flex-shrink: 0;
  border-radius: 30px;
  border-color: transparent;
  background: #5cb3cf;
}

.reset-text {
  color: #fff;
  text-align: center;
  font-size: 17px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

@media (max-width: 768px) {
  .reset-btn {
    width: 100%;
  }
}

.sentiment-select {
  height: 40px;
}

.MuiDataGrid-columnHeader {
  border: 1px solid #43addb !important;
  margin: 5px !important;
  border-radius: 15px;
}
.MuiDataGrid-root {
  padding: 10px !important;
}
.MuiDataGrid-columnHeaderTitle {
  font-weight: bold !important;
  font-family: "Rubik" !important;
  font-size: medium;
}

.error-page-container {
  height: 100vh;
  width: 100vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-image: url("./assets/img/adminbg.png");
  background-position: center center;
  object-fit: cover;
  background-size: cover;
  background-repeat: no-repeat;
}
.error-page-card {
  display: flex;
  z-index: 1;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: white;
  border-radius: 20px;
  padding: 50px 40px;
}

.error-page-card img {
  width: 100%;
}
.error-page-card h3 {
  font-family: "Rubik";
  font-weight: bold;
}
.error-desc {
  text-align: center;
  margin: 5px 10px;
  color: gray;
}

.error-guide {
  text-align: center;
}

.pdf-header {
  height: 50px;
  display: flex;
}
.pdf-header2 {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;
  margin-bottom: 20px;
}
.pdf-header2 h3 {
  margin: 0;
  padding: 0;
  font-weight: bold;
  font-family: "Rubik";
}
.pdf-header-logo {
  width: 90px;
  height: auto;
  object-fit: contain;
}

.recent-folder-checkbox {
  border: 1px solid rgb(192, 192, 192);
  margin: 0px 5px !important;
  padding-right: 15px;
  border-radius: 5px;
}

.tour-text {
  border-radius: 8px;
}

@media print {
  .report-result-cards {
    padding-top: 50px !important;
    page-break-before: always;
  }
}

.reactour-popup button svg {
  color: #43addb !important;
}

/* New CSS styling for latest figma */
.splash-screen {
  height: 100vh;
}

.login-page-container {
  min-height: 100vh;
}

.login-form-logo {
  width: 10rem;
  height: auto;
  object-fit: contain;
  z-index: 12;
}

/* -------------------- WEBSITE STYLING -------------------- */

/* styling for landing website page */
.transcribe-heading-bar {
  width: 100vw;
  font-family: "Plus Jakarta Sans", sans-serif !important;
  height: 40px;
  background: linear-gradient(90deg, #1ce1d5 0%, #5c7ce5 100%);
  text-align: center;
  padding: 8px 0px;
  color: white;
}

/* header page styling */
.header-container {
  position: relative;
  height: fit-content;
  width: 100vw;
  background-color: #0d1326;
}
.ellipse-right {
  position: absolute;
  right: 0;
}
.ellipse-left-blue {
  position: absolute;
  top: 10%;
  left: 0;
}
/* navbar styling */
.navbar-container {
  padding: 0 10%;
}
.navbar-logo-img {
  width: 6rem;
  height: auto;
  object-fit: contain;
}
.navbar-menu-container1 {
  width: fit-content;
}
.navbar-menu-container1 ul {
  list-style: none;
  padding: 0;
  z-index: 13 !important;
  margin: 0;
}
.navbar-menu-container1 ul a {
  color: white;
}

.sign-up-btn {
  cursor: pointer;
  background: linear-gradient(90deg, #5c7ce5 0%, #1ce1d5 100%) !important;
  border: none;
  color: white;
  border-radius: 5px;
  padding: 10px 20px !important;
  z-index: 13 !important;
  transition: all 0.3s ease-in-out;
}

.sign-up-btn:hover {
  box-shadow: 0px 0px 10px rgb(180, 180, 180);
  transform: scale(1.05);
}

/* mobile nav styling */
/* Hamburger icon */
.mobile-menu-icon {
  cursor: pointer;
  display: flex;
  flex-direction: column;
  gap: 8px;
  z-index: 15 !important;
}
.mobile-menu-icon.open {
  position: fixed;
  top: 50px;
  right: 20px;
}
.icon-bar {
  width: 35px;
  height: 2px;
  background-color: #fff;
  transition: 0.4s;
}
.icon-bar.open:nth-child(1) {
  transform: rotate(-45deg) translate(-8px, 6px);
}
.icon-bar.open:nth-child(2) {
  transform: rotate(45deg);
}

.mobile-navbar-container {
  background-color: #333;
  position: fixed; /* Use fixed to cover the whole viewport */
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  align-items: center;
  justify-content: center;
  z-index: 14 !important;
  transform: translateX(100%); /* Start off-screen to the right */
  transition: transform 0.3s ease-in-out; /* Smooth transition */
}

.mobile-navbar-container.open {
  transform: translateX(0); /* Slide in */
}

.mobile-menu {
  list-style: none;
  padding: 0;
  text-align: center;
}

.mobile-menu li {
  margin: 20px 0;
}
.mobile-menu li:nth-child(1) {
  margin-bottom: 3rem !important;
}
.mobile-menu li:last-child {
  margin-top: 3rem !important;
}

.mobile-menu a {
  color: #fff;
  text-decoration: none;
  font-size: 1.5rem;
}

.mobile-menu button {
  color: white;
  border: none;
  background: none;
}

/* hero container styling */

.hero-heading-title {
  position: relative;
  text-align: center; /* Center align the content */
}

.hero-heading-title h1 {
  font-family: "Plus Jakarta Sans", sans-serif;
  font-size: 3rem;
  color: white; /* Text color */
  margin-bottom: 20px; /* Adjust spacing as needed */
  position: relative; /* Ensure the text is not affected by z-index */
  z-index: 2; /* Ensure the text is above other elements */
}
.hero-heading-title h1 span {
  font-weight: bold;
  background: linear-gradient(90deg, #7595ff 0%, #1ce1d5 100%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.header-description {
  font-weight: lighter !important;
  font-family: "Plus Jakarta Sans", sans-serif;
  z-index: 2;
  text-align: center;
}

.hero-container button,
.our-features-container2 button {
  background: linear-gradient(90deg, #5c7ce5 0%, #1ce1d5 100%);
  box-shadow: none;
  border: none;
  border-radius: 5px;
  font-family: "Plus Jakarta Sans", sans-serif;
  color: white;
  padding: 15px 40px;
  font-size: 16px;
  font-weight: 500;
  z-index: 10;
  width: fit-content;
  font-weight: 700;
}

@media screen and (max-width: 1050px) {
  .header-tag-img-container img {
    width: 70% !important;
  }
  .our-features-right img {
    width: 80% !important;
    height: auto;
  }
}
@media screen and (max-width: 991px) {
  .navbar-container {
    padding: 0 2% 0 10%;
  }
  .header-tag-img-container img {
    width: 70% !important;
  }
  .our-features-left2 {
    padding-left: 0 !important;
  }
  .our-features-left2 img {
    height: 70% !important;
  }
}
@media screen and (max-width: 748px) {
  .hero-heading-title h1 {
    font-size: 3rem;
  }
  .ellipse-right {
    width: 50% !important;
    height: 50% !important;
  }
  .download-ellipse-left {
    width: 60% !important;
    height: auto !important;
  }
  .features-ellipse-right {
    width: 70% !important;
  }

  .ellipse-left-blue {
    top: 40%;
    width: 50% !important;
    height: auto !important;
  }
  .ellipse-left-blue2 {
    top: 40%;
    width: 50%;
  }
  .our-features-right img {
    width: 80% !important;
    height: auto;
  }
  .our-features-left {
    padding-left: 0 !important;
  }
  .our-features-left2 {
    padding-left: 0 !important;
  }
  .our-features-left2 img {
    width: 70% !important;
  }
}

@media screen and (max-width: 393px) {
}

.header-tag-img-container {
  display: flex;
  align-items: center;
  justify-content: center;
}
.header-tag-img-container img {
  width: 60%;
  height: 60%;
  object-fit: contain;
}

/* We CREATE DIGITAL Future */

.our-features-container1 {
  position: relative;
  color: white;
  text-align: center;
  font-family: "Plus Jakarta Sans", sans-serif !important;
  background-color: #0d1326;
}

.our-features-txt {
  text-transform: uppercase;
  width: fit-content;
  letter-spacing: 2px;
  color: #1ce1d5;
  background-color: rgba(255, 255, 255, 0.1);
  padding: 5px 20px;
  font-size: 14px;
  border-radius: 5px;
}

.slick-slide > div {
  margin: 0 10px;
}

.carousel-card {
  padding: 20px 10px;
  border-radius: 10px;
  background: rgba(255, 255, 255, 0.05);
  transition: all 0.3s ease-in-out;
}
.carousel-card:hover {
  background: linear-gradient(#090d1b, #0a0e1b) padding-box,
    linear-gradient(to right, #363636 0%, #2059d4 50%, #30a3d4 70%, #2387a6 20%) border-box;
  border: 2px solid transparent;
}

.carousel-navigation-btn {
  height: 50px;
  width: 50px;
  border-radius: 50%;
  border: none;
  color: white;
  background-color: #0d1326;
  border: 2px solid gray;
  z-index: 12;
}
.carousel-navigation-btn.grd-bg {
  background: linear-gradient(90deg, #5c7ce5 0%, #1ce1d5 100%);
  border: none;
}

.ellipse-left-blue2 {
  position: absolute;
  height: 80%;
  top: 20%;
  left: 0;
  z-index: 2;
}

.our-features-container2 {
  background-color: #0d1326;
  color: white;
}
.our-features-left {
  padding-left: 8% !important;
  font-family: "Plus Jakarta Sans", sans-serif !important;
}
.our-features-left2 {
  padding-left: 8%;
  font-family: "Plus Jakarta Sans", sans-serif !important;
}
.our-features-right img {
  width: 60%;
  height: 60%;
  object-fit: contain;
}

.our-features-left img {
  width: 70%;
  height: auto;
  object-fit: contain;
}
.our-features-left2 img {
  width: 65%;
  height: auto;
  object-fit: contain;
}
.features-ellipse-right {
  position: absolute;
  right: 0;
}
.download-app-container {
  background-color: #0d1326;
  color: white;
  font-family: "Plus Jakarta Sans", sans-serif !important;
}

.download-app-btn {
  gap: 15px;
  background-color: transparent;
  border: 1px solid white;
  padding: 10px 30px;
  border-radius: 10px;
}

.download-ellipse-left {
  position: absolute;
  left: 0;
}

.download-app-btn p:nth-child(1) {
  color: rgb(94, 94, 94);
  font-size: 12px;
}

.testimonial-card {
  cursor: pointer;
  border-radius: 10px;
  background: rgba(255, 255, 255, 0.05);
  transition: all 0.3s ease-out;
}
.testimonial-card:hover {
  background: linear-gradient(#090d1b 30%, #0a0e1b 70%) padding-box,
    linear-gradient(to right, #363636 0%, #2d2d2d 30%, #112229 50%, #2387a6 70%) border-box;
  border-radius: 10px;
  border: 2px solid transparent;
}

.custom-toggle-button {
  background-color: #ffffff;
  height: 40px;
  width: 40px;
  color: rgb(0, 0, 0);
  border: none;
  padding: 0.5rem 1rem;
  border-radius: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

@media screen and (max-width: 1823px) {
  .testimonial-desc {
    min-height: 100px;
  }
}
@media screen and (max-width: 1318px) {
  .testimonial-desc {
    min-height: 140px;
  }
}
@media screen and (max-width: 797px) {
  .testimonial-desc {
    min-height: 160px;
  }
}

.accordion-item {
  border-radius: 0.8rem !important; /* Adjust the value to your desired border radius */
  overflow: hidden;
  border: none;
  background-color: transparent;
}

.accordion-header {
  border-radius: 0.8rem;
  padding: 0.5rem 1rem;
  color: white;
  background: #0d1326;
  border: 1px solid lightgray;
  cursor: pointer;
}
.accordion-header.active {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  color: black;
  border-bottom: none;
  background: linear-gradient(90deg, #5c7ce5 0%, #1ce1d5 100%);
}

.accordion-body {
  border-bottom-left-radius: 1rem !important;
  border-bottom-right-radius: 1rem;
  padding: 1rem;
  border: 1px solid white;
  border-top: none;
}

.accordion-body.active {
  background: linear-gradient(90deg, #5c7ce5 0%, #1ce1d5 100%);
  margin-top: 0;
  padding-top: 0;
  padding-right: 10%;
}

/* footer styling */

.footer-container,
.website-footer {
  background-color: #0d1326;
  color: white;
}
.footer-divider {
  border-bottom: 1px solid gray;
}
.footer-one button {
  background-image: linear-gradient(180deg, #5c7ce5 0%, #33b3db 100%);
  box-shadow: none;
  border: none;
  border-radius: 5px;
  font-family: "Plus Jakarta Sans", sans-serif;
  color: white;
  padding: 15px 30px;
  font-size: 12px;
  z-index: 10;
  width: fit-content;
  font-weight: 700;
}

.footer-one input {
  background-color: transparent;
  padding: 10px 20px;
  border: 1px solid gray;
  border-radius: 5px;
}
.footer-one p {
  font-weight: 400;
  font-family: "Plus Jakarta Sans", sans-serif;
}
.footer-one h3 {
  font-family: "Plus Jakarta Sans", sans-serif;
}

.footer-copyrights ul a {
  text-decoration: underline;
  color: white;
}

.collapsed {
  max-height: 50px;
  text-overflow: ellipsis;
  overflow: hidden;
}

.expanded {
  max-height: none;
}

@media (min-width: 1197) and (max-width: 1308px) {
  .footer-one-searchfield {
    display: flex;
    flex-direction: column !important;
  }
}

/* Login page styling new */

.login-form-container {
  background-color: white;
  border-radius: 30px;
  padding: 25px !important;
  font-family: "Onest", sans-serif;
}
.login-form-body h4 {
  font-size: 2rem;
  font-family: "Onest", sans-serif;
}
.login-form-input label {
  color: gray;
  margin-left: 5px;
}
.login-form-input input,
.otp-form-container input {
  border-radius: 14px;
  border: 2px solid #b3b7ee;
  padding: 15px 18px;
}
.login-form-input .country-input {
  border-radius: 16px !important;
  border: 2px solid #b3b7ee;
}
.MuiSelect-outlined {
  border-radius: 5px solid red !important;
}
.login-form-input input.password {
  border: none;
}
input.password:focus {
  box-shadow: none;
}
.input-group {
  border-radius: 14px;
  border: 2px solid #b3b7ee;
}
.input-group-text {
  background-color: transparent;
  border: none;
  border-radius: 14px;
}
/* Basic styling for the checkbox */
.custom-checkbox {
  appearance: none; /* Remove default styles */
  width: 15px;
  height: 15px;
  border: 2px solid #b3b7ee; /* Default border color */
  border-radius: 4px; /* Optional: round corners */
  position: relative;
  cursor: pointer;
  padding: 2px;
}

/* Styling for the checked state */
.custom-checkbox:checked {
  background-color: #5051fa;
  border-color: #5051fa;
  color: white; /* Change to your desired color */
}

/* Optional: Styling for a checkmark */
.custom-checkbox:checked::after {
  content: "✓";
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.login-form-button {
  padding: 12px 15px;
  color: white;
  border: none;
  border-radius: 14px;
  background-color: #5c7ce5;
  font-family: "Onest", sans-serif;
  box-shadow: 0px 4px 10px 0px rgba(92, 124, 229, 0.3);
}
.welcome-audio-buttons button {
  padding: 12px 20px;
  color: white;
  border: none;
  border-radius: 12px;
  background-color: #5c7ce5;
  font-family: "Onest", sans-serif;
  box-shadow: 0px 4px 10px 0px rgba(92, 124, 229, 0.3);
}
.login-form-button.disabled {
  background-color: gray;
}

.login-page-right {
  background: url("./assets/img/login-bg.png");
  object-fit: cover;
  background-size: cover;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

.login-page-right-card {
  display: flex;
  padding: 40px 30px 0 30px !important;
  flex-direction: column;
  justify-content: space-between;
  background: rgba(255, 255, 255, 0.1); /* Optional: Add glass effect */
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* Optional: Add shadow */
  backdrop-filter: blur(10px); /* Optional: Add frosted glass effect */
  -webkit-backdrop-filter: blur(10px); /* Optional: Add frosted glass effect for Safari */
  border: 1px solid rgba(255, 255, 255, 0.2); /* Optional: Add border */
  color: white;
  gap: 30px;
}

.login-img-wrapper {
  text-align: center;
}

.login-female-img {
  max-width: 70%;
  height: auto;
  margin-left: 50px;
}
.login-male-img {
  max-width: 70%;
  height: auto;
  margin-left: 0px;
}

.login-page-right-card .login-chat-img {
  position: absolute;
  top: 65%;
  left: -50px;
  height: 120px;
  width: 120px;
}
.login-page-right-card img:nth-child(2) {
  position: absolute;
  right: -50px;
  top: 20%;
  height: 100px;
  width: 100px;
}

.signup-ellipse-img {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 80% !important;
}
.signup-ellipse-top {
  position: absolute;
  top: 0;
  right: 0;
  width: 80% !important;
}

/* otp page styling */

.otp {
  background-color: rgba(92, 124, 229, 0.15);
  border-radius: 14px;
  border: none;
  font-family: "Onest", sans-serif;
  font-weight: bold;
}
.otp:focus {
  background-color: white;
  outline: 2px solid #5c7ce5;
}

/* audio upload page styling */

.upload-audio-container {
  height: fit-content;
  min-height: 360px;
  border-radius: 17px;
  border: 3px dashed #d7d6f7;
  font-family: "Onest", sans-serif;
  background-color: white;
}
.drag-overlay{
  position: absolute;
  inset: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(61, 129, 255, 0.834);
  border-radius: 17px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.drag-overlay p{
  font-size: 30px;
  font-weight: bolder;
}
.table-header {
  border-radius: 16px 16px 0px 0px !important;
  background-color: #5c7ce5;
}

.table-container {
  border-radius: 0px 16px 16px 16px !important;
  border-top-left-radius: 16px !important;
  scrollbar-width: none;
}

.table-container::-webkit-scrollbar {
  display: none; /* Hides the scrollbar */
}

.MuiTableRow-root td:first-child {
  border-top-left-radius: 10px;
}

.MuiTableRow-root td:last-child {
  border-top-right-radius: 10px;
}

.report-header {
  border-top-left-radius: 16px;
  border-top-right-radius: 16px;
  background: linear-gradient(82.31deg, #5c7ce5 1.12%, #1ce1d5 151.49%);
  color: white;
}

.MuiTableCell-root {
  border-bottom: none !important;
}

.table-pagination {
  color: #5c7ce5 !important;
  font-family: "Onest", sans-serif;
}

.summary-side {
  font-family: "Onest", sans-serif;
}
.summary-side h4 {
  white-space: normal;
  word-wrap: break-word;
}

.audio-report-container {
  word-wrap: break-word; /* Break long words and wrap them */
  overflow: hidden; /* Hide overflow */
  text-overflow: ellipsis; /* Add ellipsis (...) for overflowed text */
  white-space: nowrap; /* Add ellipsis (...) for overflowed text */
}

.selected-audio-folder-card {
  padding: 30px;
  background-color: white;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
}

.btn-close {
  display: flex;
  align-items: center;
  cursor: pointer;
  margin-right: 0px !important;
  margin-top: 2px !important;
  justify-content: center;
  background: linear-gradient(82.31deg, #003cff 1.12%, #1ce1d5 151.49%);
  border-radius: 50%;
  color: white;
  font-weight: 500 !important;
  padding: 2px !important;
}

.modal-content {
  border-radius: 20px !important;
  font-family: "Onest", sans-serif;
}

.inline-list {
  padding-left: 0;
  height: 480px;
  overflow: auto;
}
.inline-list li {
  display: inline-block;
  margin-right: 10px;
  line-height: 100%;
  background-color: #5c7ce5;
  word-break: break-all; /* Ensures the word breaks to the next line */
  border-radius: 5px;
  padding: 5px;
  color: white;
  margin-bottom: 5px;
}

.transcribed-section ul {
  padding-left: 8px !important;
}

.conversation-role {
  width: 120px;
}

.terms-header-container {
  width: 100%;
  height: 350px;
  background: linear-gradient(180deg, #5c7ce5 0%, #1ce1d5 100%);
  position: relative;
  clip-path: url(#rounded-bottom-clip);
  overflow: hidden;
}
.terms-header-container h1 {
  font-family: "OpenSans", sans-serif;
  font-size: 3rem;
}

.terms-header-container p {
  position: relative;
  color: #5d5dde !important;
  background: #5070b45c;
  font-weight: bold;
  letter-spacing: 6px;
  line-height: 25px;
  text-transform: uppercase;
  padding: 7px 30px;
  font-size: 14px;
  font-family: "OpenSansCondensed", sans-serif;
  border-radius: 7px;
  border: 1px solid #5c7ce5;
}

@media (max-width: 768px) {
  .terms-header-container {
    clip-path: url(#rounded-bottom-clip-mobile);
  }
  .tc-topleft-poly {
    position: absolute;
    height: 100px !important;
    top: -50px !important;
    left: -40px !important;
  }
  .tc-bottomleft-poly {
    position: absolute;
    height: 90px !important;
    bottom: -27px !important;
  }
  .tc-right-poly {
    position: absolute;
    height: 90px !important;
    right: -30px !important;
  }
}

@media (min-width: 769px) {
  .terms-header-container {
    clip-path: url(#rounded-bottom-clip);
  }
}

.terms-page p {
  color: #212529;
}

.tc-topleft-poly {
  position: absolute;
  height: 180px;
  width: auto;
  top: -80px;
  left: -60px;
}
.tc-bottomleft-poly {
  position: absolute;
  height: 150px;
  width: auto;
  bottom: -50px;
  left: 20%;
}
.tc-blocks {
  position: absolute;
  height: 80px;
  width: auto;
  top: 5%;
  right: 20%;
}
.tc-cells {
  position: absolute;
  height: 70px;
  width: auto;
  top: -15px;
  right: 8%;
}
.tc-right-poly {
  position: absolute;
  height: 150px;
  width: auto;
  bottom: 30%;
  right: -50px;
}

.terms-point-list {
  list-style-type: none;
  padding-left: 5px;
  margin-left: 0px !important;
}
.terms-point-list li {
  margin-left: 0px !important;
}

.terms-list-heading::marker {
  font-size: 26px !important;
  font-weight: 500;
}
.terms-list-heading h3 {
  font-weight: 500;
}

.terms-back-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50px;
  height: 50px;
  background-color: white;
  border-radius: 50%;
  position: absolute;
  top: 10%;
  left: 4%;
  cursor: pointer;
}
.terms-back-icon {
  margin-left: 6px;
}
.terms-point-heading {
  min-width: fit-content;
  word-wrap: break-word;
  white-space: normal;
}

/* contact us page styling */

.contact-footer-card {
  min-height: 300px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  border: 1px solid lightgray;
  padding: 10px;
  border-radius: 14px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

@media screen and (max-width: 1078px) {
  .contact-footer-card {
    min-height: 350px;
  }
}

@media screen and (max-width: 768px) {
  .contentFooter {
    margin-top: 900px !important;
  }
}
@media screen and (max-width: 510px) {
  .contentFooter {
    margin-top: 1000px !important;
  }
}
@media screen and (min-width: 992px) {
  .report-chart-container {
    min-height: 750px;
  }
}

.btn.btn-link {
  z-index: 15 !important;
}

.point-heading {
  width: 200px;
  word-break: break-all;
  white-space: normal;
}
@media screen and (max-width: 992px) {
  .point-heading {
    width: 160px;
  }
}
@media screen and (max-width: 768px) {
  .point-heading {
    width: 120px;
    word-break: keep-all;
  }
  .terms-point-list li {
    margin-left: -10px !important;
  }
}
.folder-list{
  max-height: 200px; 
  overflow-y: scroll;
}

input[type="radio"] {
  width: 18px;
  height: 18px;
  cursor: pointer;
  accent-color: #5c7ce5;
  border-color: red !important;
}

.openNavButton{
  position: absolute;
  height: 30px;
  top: 10px;
  left: 20px;
  margin: auto;
  z-index: 10;
  padding: 0 !important;
}
.bi-layout-sidebar{
  font-size: 24px;
  color: #5c7ce5;
  cursor: pointer;
}